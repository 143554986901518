<template>
  <div class="page">
    <Navbar title="金豆兑换" />
    <van-field
      v-model="balance"
      name="可兑换金豆"
      label="可兑换金豆"
      readonly
      placeholder="可兑换金豆"
      ><template #button> 个 </template></van-field
    >
    <van-field
      v-model="amount"
      name="兑换数量"
      type="number"
      label="兑换数量"
      @change="calculate"
      placeholder="请填写兑换数量"
      :rules="[{ required: true, message: '请填写兑换数量' }]"
      ><template #button> 个 </template></van-field
    >
    <van-field
      v-model="money"
      name="兑换金额"
      type="number"
      label="兑换金额"
      placeholder="请填写兑换数量"
      :rules="[{ required: true, message: '请填写兑换数量' }]"
      ><template #button> 元 </template></van-field
    >
    <van-button
      class="button"
      :color="COLOR_M"
      icon="plus"
      size="small"
      @click="exchange()"
      >立即兑换</van-button
    >
    <Loading :show="loadingShow" />
    <Share ref="share" />
  </div>
</template>
<script>
import BusinessExchange from './BusinessExchange.js'
export default {
  ...BusinessExchange
}
</script>
<style lang="less" scoped>
@import './BusinessExchange.less';
</style>
